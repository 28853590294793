import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import NotFound from '../templates/NotFound';

const NotFoundPage: React.FC = () => {

  const data = useStaticQuery(graphql`
    query Page404 {
      contentfulSiteConfig {
        page404 {
          heading
          body {
            raw
          }
        }
      }
    }
  `);
  const {
    page404: {
      heading,
      body: { raw },
    },
  } = data.contentfulSiteConfig;

  return (
    <NotFound heading={heading} bodyObject={JSON.parse(raw)} />
  );
};

export default NotFoundPage;
